.addCustomerButton {
	position: absolute;
	width: 146px;
	right: 34px;
	top: 77px;
	font-family: 'Segoe UI';
	svg {
		margin-right: 8px;
	}
}
.page {
	display: none;
}
.footerPadding {
	padding-bottom: 20px;
}
.headerPadding {
	padding-left: 40px;
}
.contentPadding {
	padding-left: 32px;
}

.businessTypePanel {
	.ms-Panel-scrollableContent {
		height: 100%;
		.ms-Panel-content {
			height: calc(100% - 50px);
		}
	}
}

.label {
	font-family: Segoe UI;
	font-size: 14px;
	line-height: 20px;
}
.addAddressPadding {
	padding-top: 30px;
}

.headerLabel {
	font-family: Segoe UI;
	font-size: 26px;
	line-height: 32px;
	color: #000000;
	text-align: left;
	padding: 0;
	margin: 28px 48px;
}

.businessTypeDropdown {
	width: 180px;
}

@media (max-width: 576px) {
	.addCustomerButton {
		position: static;
		margin-left: 48px;
		margin-bottom: 20px;
	}
}

.ms-Callout-main {
	min-width: 110px;
}
