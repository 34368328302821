.root {
  .actionContainer {
    margin-bottom: 35px;
    .search {
      height: 36px;
      background: #ffffff;
      border: 1px solid #8a8886;
      box-sizing: border-box;
      border-radius: 2px;
      width: 654px;
      input {
        font-family: Segoe UI;
        font-size: 18px;
        line-height: 20px;
        color: #646464;
        &::placeholder {
          font-family: Segoe UI;
          font-size: 18px;
          line-height: 20px;
          color: #646464;
        }
      }
    }
    .button {
      background: #ffffff;
      font-family: Segoe UI;
      font-size: 14px;
      line-height: 20px;
      color: #323130;
      height: 36px;
      width: 110px;
      padding: 0 8px;
      border-color: #0064c1;
      svg {
        margin-right: 8px;
      }
    }
  }
  .separator {
    height: 1px;
    padding-bottom: 0;
    &::before {
      background-color: #c6c6c6;
    }
  }
}

@media (max-width: 768px) {
  .root{
    .actionContainer{
      .search{
        width: 350px;
      }
    }
  }
}

@media (max-width: 576px) {
  .root{
    .actionContainer{
      .search{
        width: 150px;
      }
    }
  }
}

@media (forced-colors: active) {
  .separator {
    height: 1px;
    padding-bottom: 0;
    &::before {
      background-color:Highlight !important;
    }
  }
}
