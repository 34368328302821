.root {
	padding: 28px 28px 28px 48px;
	.label {
		font-family: Segoe UI;
		font-size: 26px;
		line-height: 32px;
		color: #000000;
		text-align: left;
		padding: 0;
		margin-bottom: 28px;
	}
}

@media (max-width: 768px) {
	.root {
		padding-left: 24px;
		padding-right: 14px;
	}
}

@media (max-width: 576px) {
	.root {
		padding-left: 12px;
		padding-right: 7px;
	}
}
