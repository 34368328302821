.root {
	padding-bottom: 30px;
	font-family: Segoe UI;
	color: #171717;
	.header {
		font-size: 26px;
		line-height: 32px;
		font-weight: 700;
	}
	.subHeader {
		font-size: 14px;
		line-height: 22px;
		font-weight: 700;
	}
	.text {
		font-size: 14px;
		line-height: 20px;
		margin-top: 30px;
	}
	.separator {
		&::before {
			background-color: #c6c6c6;
		}
		margin: 48px 0 15px 0;
	}
	.label {
		font-size: 14px;
		line-height: 20px;
		margin: 8px 0;
		text-decoration: underline;
		&.copyright {
			color: #646464;
			font-weight: 400;
		}
		&.support {
			margin-top: 34px;
		}
	}
}

.screenshot {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	margin-top: 20px;
	margin-bottom: 20px;
}
