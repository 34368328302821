.root {
  .separator {
    &::before {
      background-color: #c6c6c6;
    }
  }
  .buttons {
    padding: 10px 8px 22px 8px;
    button {
      margin: 0 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 19px;
      box-sizing: border-box;
      border-radius: 2px;
      font-family: Segoe UI;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
    .save {
      background: #0078d4;
      border: 1px solid #ffffff;
      color: #ffffff;
      &:disabled{
          opacity: 0.5;
      }
    }
    .cancel {
      background: #ffffff;
      border: 1px solid #646464;
      color: #171717;
    }
  }
}
