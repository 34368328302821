/* DocumentsPage */

.horizontal-line {
	width: 100%;
	background-color: #c6c6c6;
}

/* DocumentsForm */

.documents-form {
	margin: 24px 0;
	align-items: flex-end;
}

.form-btns {
	display: flex;
	margin-left: -6px;
}

.form-btn {
	margin: 10px 6px 0px 6px;
}

/* TableFunctions */

.void-btn {
	color: rgb(32, 31, 30);
	padding-right: 8px;
}

.void-btn:hover {
	color: rgb(32, 31, 30);
	background-color: rgb(243, 242, 241);
}

.file-off-icon {
	margin: 3px 8px 0 4px;
}

.customize-icon {
	margin: 3px 8px 0 4px;
}

.table-btns {
	display: flex;
	flex-wrap: wrap;
}

.customize-btn {
	color: rgb(32, 31, 30);
	padding-right: 8px;
	margin-right: 44px;
}

.customize-btn:hover {
	color: rgb(32, 31, 30);
	background-color: rgb(243, 242, 241);
}

/* TableFooter */

.table-footer {
	margin: 22px 0 0 12px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.results-found {
	/* align-self: center; */
	font-weight: normal;
}

.results-found-value {
	font-weight: bold;
	margin-right: 10px;
}

/* .page-size {
	align-self: center;
} */

/* Overlay */

.overlay {
	position: absolute;
	right: 80px;
	width: 24%;
	min-width: 120px;
	background-color: #ffffff;
	z-index: 1;
	box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.132),
		0px 1.2px 3.5px rgba(0, 0, 0, 0.108);
	border-radius: 2px;
}

.close-btn {
	color: black;
	display: block;
	margin-left: auto;
}

.close-btn:hover {
	color: black;
}

h6 {
	font-weight: bold;
	margin-left: 19px;
	text-align: left;
	font-size: 16px;
	margin-bottom: 33px;
}

.header-item {
	display: flex;
	margin-left: 19px;
	margin-bottom: 19px;
}

.checkbox {
	zoom: 0.8;
	padding-top: 5px;
	padding-right: 7px;
}

.header-text {
	font-size: 13.5px;
}

.overlayLine {
	margin-top: 0;
	margin-bottom: 0;
	background-color: #c6c6c6;
	width: 90%;

}

.overlay-btns {
	float: left;
}
.overlay-btn {
	font-size: 13px;
	margin: 19px;
	margin-right: 0;
}

  @media screen and (-ms-high-contrast: black-on-white) {
	.root-369 {
		background:none !important;		
		-ms-high-contrast-adjust: none;
	}
	.cell-370  {
		background:none !important;		
		-ms-high-contrast-adjust: none;
	}
	.textColor-389 {
		color: black !important;
		-ms-high-contrast-adjust: none;
	}
	.numColumns-112 {
		color: black !important;
		-ms-high-contrast-adjust: none;
	}
  }
  @media screen and (-ms-high-contrast: white-on-black) {
	.root-369 {
		background:none !important;		
		-ms-high-contrast-adjust: none;
	}
	.cell-370  {
		background:none !important;		
		-ms-high-contrast-adjust: none;
	}
	.textColor-389 {
		color: white !important;
		-ms-high-contrast-adjust: none;
	}
	.numColumns-112 {
		color: white !important;
		-ms-high-contrast-adjust: none;
	}
  }
