.IconCompletedColor {
	color: #107c10;
}
.IconPendingColor {
	color: #d83b01;
}

.table-footer-log {
	margin: 22px 0 0 12px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.results-found-log {
	// align-self: center;
	font-weight: normal;
}

.results-found-value-log {
	font-weight: bold;
	margin-right: 10px;
}

// .page-size-log {
// 	align-self: center;
// }

.line {
	margin: 24px 0 !important;
	background-color: #c6c6c6;
}
