.navbarContainer {
	background-color: #0078d4;
	height: 48px;
	justify-content: space-between;
}

.container {
	min-height: 100vh;
}

.content {
	flex-grow: 1;
	// flex-shrink: 0;
	overflow-x: auto;
}

.skip {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.skip:focus {
	background: #e6e6e6;
	color: #0067b8;
	position: fixed;
	top: 0;
	left: 0;
	padding: 24px;
	width: auto;
	height: auto;
	overflow: auto;
	right: 0;
	text-decoration: underline;
	text-align: center;
	z-index: 800;
	outline: none;
}
