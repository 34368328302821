.root {
  height: 32px;
  display: flex;
  justify-content: center;
  > div {
    width: 350px;
    position: absolute;
    top: 25px;
  }
}
