.but {
	position: absolute;
	right: 10px;
	top: 4px;
	background-color: White !important;
	color: black !important;
}
.but:focus {
 border: 2px solid black !important;
}

.but:focus{
	outline: 1px solid white;
}

