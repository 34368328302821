.root {
  .choice {
    > div > div {
      display: flex;
      flex-direction: column;
      margin-left: 40%;
    }
  }
  .blank{
    color: transparent;
  }
  .finance {
    padding-top: 5px;
    margin-left: 40%;
    > div {
      margin: 3px 0;
    }
  }
  .checkbox {
    justify-content: center;
    margin-top: 6px;
  }
  .tableContainer {
    overflow: auto;
    table {
      overflow-x: auto;
      border-collapse: collapse;
      font-family: Segoe UI;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0em;
      color: #171717;
      .PostUpdate{
        font-size: 20px;
        color: #0078D4;
        padding-left: 10px;
        cursor: pointer;
        padding-top: 3px;
      }
      th {
        text-align: left;
        font-weight: 700;
      }

      td,
      th {
        padding: 15px;
        border-bottom: 1px solid #ebebeb;
        min-width: 130px;
      }

      th:nth-of-type(1) {
        min-width: 250px;
      }
      td:nth-of-type(1) {
        text-align: left;
      }
      th:not(:first-child) {
        text-align: center;
      }
      td:last-child {
        text-align: center;
      }
    }
  }
}
