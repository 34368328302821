.root {
	display: flex;
	margin-bottom: 24px;
	.textbox {
		margin-right: 21px;
		font-size: 14px;
		line-height: 20px;
		border: 1px solid #8c8c8c;
		// input {
		// 	color: #171717;
		// }
	}
	.buttons {
		margin-left: 8px;
		.cancel {
			width: 70;
			margin-left: 12px;
		}
	}

	 @media screen and (-ms-high-contrast: white-on-black) {
		.textbox {
			margin-right: 21px;
			font-size: 14px;
			line-height: 20px;
			border: 1px solid #8c8c8c;
		}
  	}
}

@media (max-width: 576px) {
	.root {
		display: block;

		.textbox {
			margin-right: 0px;
			margin-bottom: 5px;
			width: auto;
		}
		.buttons {
			margin-left: 0px;
			.cancel {
				margin-left: 5px;
			}
		}
	}
}
