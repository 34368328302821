.root {
  font-family: Segoe UI;
  .label {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }
  .link {
    color: #0050a9;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (-ms-high-contrast: white-on-black) {
    .link {
      color: #FFEE32;
      text-decoration: none;
      border: 1px solid #FFEE32;
      &:hover {
        text-decoration: none;
      }

      .fieldGroup-257 {
        border-color: #A6A6A6;
        //forced-color-adjust: none;
    }
    }
  }

  @media screen and (-ms-high-contrast: black-on-white) {
    .link {
      color: #202020;
      text-decoration: none;
      border: 1px solid #202020;
      &:hover {
        text-decoration: none;
      }

    }
  }
}
