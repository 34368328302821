.root {
  padding: 0 40px 0 24px;
  .label {
    font-size: 26px;
    line-height: 32px;
    color: #000000;
    font-weight: 700;
  }
  .group {
    margin: 12px 0;
    .fieldLabel {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #171717;
      &.required {
        &::after {
          color: red;
          content: "*";
        }
      }
    }
    .checkbox {
      margin-bottom: 12px;
    }
    .choice {
      > div > div {
        display: flex;
        flex-direction: row;
        padding-left: 12px;
        margin-bottom: 12px;
        > div {
          margin-right: 12px;
          margin-top: 0;
        }
      }
    }
    .finance {
      padding-left: 12px;
      margin-bottom: 12px;
      > div {
        margin-right: 12px;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    .cancel {
      width: 70;
    }
  }
}
