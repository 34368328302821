.root {
	.actionContainer {
		margin-bottom: 35px;
		.search {
			height: 36px;
			background: #ffffff;
			border: 1px solid #8a8886;
			box-sizing: border-box;
			border-radius: 2px;
			width: 654px;
			input {
				font-family: Segoe UI;
				font-size: 18px;
				line-height: 20px;
				color: #646464;
				&::placeholder {
					font-family: Segoe UI;
					font-size: 18px;
					line-height: 20px;
					color: #646464;
				}
			}
		}
		.button {
			position: absolute;
			right: 28px;
			margin-top: 28px;
			background: #ffffff;
			font-family: Segoe UI;
			font-size: 13px;
			line-height: 20px;
			color: #323130;
			height: 36px;
			width: 150px;
			padding: 0 8px;
			border-color: #0064c1;
			svg {
				margin-right: 8px;
			}
		}
	}
	.separator {
		height: 1px;
		padding-bottom: 0;
		&::before {
			background-color: #8C8C8C;
		}
	}
}

@media (max-width: 768px) {
	.root {
		.actionContainer {
			.search {
				width: 350px;
			}
		}
	}
}

@media (max-width: 576px) {
	.root {
		.actionContainer {
			.search {
				width: 150px;
			}
			.button {
				position: relative;
				right: 0px;
			}
		}
	}
}
